.HomePage {

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    .header {

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;

        // Mobile
        @media (max-width: 800px) {
            justify-content: center;
        }

        // Desktop
        @media (min-width: 801px) {
            justify-content: space-between;
            gap: 55px;
        }

        width: 100%;
        height: 110px;
        padding: 0 80px;

        border-top: 8px solid #296DF1;

        .left {

            // Mobile
            @media (max-width: 800px) {
                text-align: center;
            }

            h1 {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 23px;
                line-height: 24px;
                /* identical to box height */

                letter-spacing: 1.3px;
                text-transform: uppercase;

                color: #000000;
            }

            h2 {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;

                letter-spacing: 0.5px;
                text-transform: uppercase;

                color: #296DF1;

                margin-top: 2px;
            }
        }

        .right {
            /* Auto layout */

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0px;
            gap: 40px;

            .item {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                /* identical to box height */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 1px;
                text-transform: uppercase;

                color: #000000;

            }
        }
    }

    .content-top {

        /* Auto layout */
        display: flex;
        padding: 0px;

        // Mobile
        @media (max-width: 800px) {
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 55px;
            margin: 40px 0 40px;
            padding: 0 24px;
        }

        // Desktop
        @media (min-width: 801px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 55px;
            max-width: 900px;
            margin: 80px 60px;
            min-height: calc(100vh - 110px - 160px);
        }

        .left {}

        .right {

            h2 {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                /* identical to box height */

                display: flex;
                align-items: center;
                letter-spacing: 1px;
                text-transform: uppercase;

                color: #888888;

                margin-bottom: -4px;
            }

            h1 {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 47px;
                display: flex;
                align-items: center;

                color: #000000;

                margin-bottom: -4px;

                div {
                    font-family: 'Sacramento';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 70px;
                    line-height: 102px;

                    color: #296DF1;

                    margin-left: 7px;
                    margin-right: 3px;
                }
            }

            p {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 155%;

                color: #4A4A4A;

                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 30px;
                }
            }

            .tags {
                display: block;

                .tag {
                    /* Auto layout */

                    display: inline-block;
                    padding: 6px 11px;
                    margin: 0 11px 11px 0;
                    border-radius: 20px;

                    background: #296DF127;
                    color: #296DF1;
                    // color: #ffffffee;

                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1px;

                    // background: #29a4f127;
                    // color: #29a4f1;

                    // &.tag-creative {
                    //     background: #29a4f127;
                    //     color: #29a4f1;
                    // }
                }
            }
        }
    }

    .content-below {

        // background-color: #fafafa;

        
        /* Auto layout */
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        justify-content: center;
        
        // Mobile
        @media (max-width: 800px) {
            padding: 0 24px 40px;
        }
        
        // Desktop
        @media (min-width: 801px) {
            padding: 0 60px 50px;
        }
        
        .wrapper {
            
            border-top: 1px solid #eee;

            // Mobile
            @media (max-width: 800px) {
                padding-top: 24px;
            }
            
            // Desktop
            @media (min-width: 801px) {
                padding-top: 50px;
            }

            /* Auto layout */
            display: flex;

            // Mobile
            @media (max-width: 800px) {
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 4px;
                text-align: center;
            }

            // Desktop
            @media (min-width: 801px) {
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-start;
                gap: 64px;
                max-width: 1200px;
            }
        }

        .left,
        .right {
            flex-basis: 33%;
            // border: 1px solid #ddd;
            // border-radius: 15px;
            // padding: 4px 22px;
            // box-shadow: 2px 2px 4px #aaa;
        }

        .heading {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 1px;
            text-transform: uppercase;

            color: #888888;
            // color: #4a4a4a;

            margin-top: 16px;
            margin-bottom: 12px;

            // Mobile
            @media (max-width: 800px) {
                margin-top: 34px;
            }
        }

        p {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 155%;

            color: #4A4A4A;

            margin-bottom: 14px;
        }

        .tags {
            display: block;

            .tag {
                /* Auto layout */

                display: inline-block;
                padding: 6px 11px;
                margin: 0 11px 11px 0;
                border-radius: 20px;

                background: #296DF127;
                color: #296DF1;
                // color: #ffffffee;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 14px;
                text-transform: uppercase;
                letter-spacing: 1px;

                // background: #29a4f127;
                // color: #29a4f1;

                // &.tag-creative {
                //     background: #29a4f127;
                //     color: #29a4f1;
                // }
            }
        }
    }
}